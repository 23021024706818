import _ from "lodash";
// import { LayoutPublic } from "../components/LayoutPublic";
// import ContactBTN from "../components/ContactBTN";
import HeaderHeroBlock from "../components/Blocks/HeaderHero";
// import TextGridWithTitleBlock from "../components/Blocks/TextGridWithTitle";
// import CtaAccordionBlock from "../components/Blocks/CtaAccordion";
// import CtaContactUsBlock from "../components/Blocks/CtaContactUs";
// import CustomerReviewsBlock from "../components/Blocks/CustomerReviews";
import CtaBenefitsBlock from "../components/Blocks/CtaBenefits";
// import FrecuentAnswersBlock from "../components/Blocks/FrecuentAnswers";
import CtaDownloadBlock from "../components/Blocks/CtaDownloadBlock";
import FooterBlock from "../components/Blocks/FooterBlock";
import { blocks } from "../stringsMap";
import { LayoutHome } from "../components/LayoutHome";
import { IonContent, IonPage } from "@ionic/react";


const HeaderMenu = ({ menuHeight }) => {
  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <button
        className="pl-10 sm:pl-2 sm md:px-3 text-black hover:text-brand-yellow md:font-medium text-sm md:text-base cursor-pointer"
        onClick={() => scrollToElement("inicio")}
      >
        Inicio
      </button>
      <button
        className="pl-10 sm:pl-2 md:px-3 text-black hover:text-brand-yellow md:font-medium text-sm md:text-base cursor-pointer"
        onClick={() => scrollToElement("beneficios")}
      >
        Servicios
      </button>
      <button
        className="pl-10 sm:pl-2 md:px-3 text-black hover:text-brand-yellow md:font-medium text-sm md:text-base cursor-pointer"
        onClick={() => scrollToElement("descarga")}
      >
        Descarga
      </button>
      <button
        className="pl-10 sm:pl-2 md:px-3 text-black hover:text-brand-yellow md:font-medium text-sm md:text-base cursor-pointer"
        onClick={() => scrollToElement("footer")}
      >
        Contactos
      </button>
    </>
  );
};

export function PublicHome(props) {
  return (
    <IonPage id="main">
      <IonContent className="">
        <LayoutHome mainSectionClass="px-0" HeaderMenu={HeaderMenu}>
          {/* Header Hero */}
          <div id="inicio">
            <HeaderHeroBlock
              data={blocks.headerHero}
              UnderText={() => (
                <div className="hidden lg:block px-4 lg:pt-10">
                  <CtaDownloadBlock
                    data={blocks.ctaDownload}
                    {...props}
                    classes={{
                      blockContainer: "px-4 py-6 bg-brand-red rounded-md",
                    }}
                  />
                </div>
              )}
            />
          </div>

          <div className="lg:hidden">
            <CtaDownloadBlock
              data={blocks.ctaDownload}
              {...props}
              classes={{ blockContainer: "py-14 lg:py-20 px-4 bg-brand-red" }}
            />
          </div>

          <div id="beneficios">
            <CtaBenefitsBlock data={blocks.ctaBenefits} />
          </div>

          <div id="descarga">
            <CtaDownloadBlock
              data={blocks.ctaDownload}
              {...props}
              classes={{ blockContainer: "py-14 lg:py-20 px-4 bg-brand-red" }}
            />
          </div>

          <div id="footer">
            <FooterBlock data={blocks.footerSection} />
          </div>
        </LayoutHome>
      </IonContent>
    </IonPage>
  );
}
